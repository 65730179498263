import React from 'react';
import { 
    Paper, 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow,
    Typography,
    Stack,
    Chip,
    Box,
    Tooltip
} from '@mui/material';
import { BankLogo } from './BankLogo';
import { BankDetails, BanksNotIncluded, ColorType } from '../types';

interface BankTableProps {
    banks: {
        Boliglånsbanker: { [key: string]: BankDetails };
        Spesiallånsbanker: { [key: string]: BankDetails };
    };
    validBanks: string[];
    banksNotIncluded: { [key: string]: BanksNotIncluded };
}

const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('no-NO', { 
        style: 'currency', 
        currency: 'NOK',
        maximumFractionDigits: 0 
    }).format(amount);
};

const formatPercentage = (value: number) => {
    return `${value.toFixed(2)}%`;
};

const getColorStyle = (color: ColorType) => {
    switch (color) {
        case 'red':
            return { bgcolor: '#d32f2f', color: 'white' };
        case 'green':
            return { bgcolor: '#2e7d32', color: 'white' };
        case 'yellow':
            return { bgcolor: '#ed6c02', color: 'white' };
        default:
            return { bgcolor: '#757575', color: 'white' };
    }
};

interface BankTableSectionProps {
    banks: { [key: string]: BankDetails } | undefined;
    validBanks: string[];
    title: string;
}

const BankTableSection = ({ banks, validBanks, title }: BankTableSectionProps) => {
    if (!banks) return null;

    const filteredBanks = Object.entries(banks)
        .filter(([bankName]) => validBanks.includes(bankName));

    if (filteredBanks.length === 0) return null;

    return (
        <TableContainer component={Paper} sx={{ mb: 3, width: '100%' }}>
            <Typography variant="h6" sx={{ p: 2 }}>
                {title}
            </Typography>
            <Table size="medium" sx={{ width: '100%' }}>
                <TableHead>
                    <TableRow>
                        <TableCell width="15%"><strong>Bank</strong></TableCell>
                        <TableCell width="12%" align="right"><strong>Sannsynlighet</strong></TableCell>
                        <TableCell width="12%" align="right"><strong>Nom. rente</strong></TableCell>
                        <TableCell width="15%" align="right"><strong>Mnd terminbeløp</strong></TableCell>
                        <TableCell width="10%" align="right"><strong>FLI</strong></TableCell>
                        <TableCell width="15%" align="right"><strong>Overskuddslikviditet</strong></TableCell>
                        <TableCell width="21%"><strong>Kommentarer</strong></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filteredBanks.map(([bankName, details]) => (
                        <TableRow key={bankName}>
                            <TableCell width="15%">
                                <Stack 
                                    direction="column" 
                                    spacing={1} 
                                    alignItems="center"
                                    sx={{ py: 1 }}
                                >
                                    <BankLogo bankName={bankName} size={100} />
                                    <Typography 
                                        variant="caption" 
                                        color="text.secondary"
                                        align="center"
                                    >
                                        {bankName}
                                    </Typography>
                                </Stack>
                            </TableCell>
                            <TableCell width="12%" align="right">
                                <Tooltip title={details.prob.caption} arrow>
                                    <Chip 
                                        label={`${Number(details.prob.value).toFixed(1)}%`}
                                        sx={{ ...getColorStyle(details.prob.color) }}
                                        size="small"
                                    />
                                </Tooltip>
                            </TableCell>
                            <TableCell width="12%" align="right">
                                <Tooltip title={details.nomRente.caption} arrow>
                                    <Chip 
                                        label={formatPercentage(Number(details.nomRente.value))}
                                        sx={{ ...getColorStyle(details.nomRente.color) }}
                                        size="small"
                                    />
                                </Tooltip>
                            </TableCell>
                            <TableCell width="15%" align="right">
                                <Tooltip title={details.mndTerminbeløp.caption} arrow>
                                    <Chip 
                                        label={formatCurrency(Number(details.mndTerminbeløp.value))}
                                        sx={{ ...getColorStyle(details.mndTerminbeløp.color) }}
                                        size="small"
                                    />
                                </Tooltip>
                            </TableCell>
                            <TableCell width="10%" align="right">
                                <Tooltip title={details.FLI.caption} arrow>
                                    <Chip 
                                        label={Number(details.FLI.value).toFixed(2)}
                                        sx={{ ...getColorStyle(details.FLI.color) }}
                                        size="small"
                                    />
                                </Tooltip>
                            </TableCell>
                            <TableCell width="15%" align="right">
                                <Tooltip title={details.Overskuddslikviditet.caption} arrow>
                                    <Chip 
                                        label={formatCurrency(Number(details.Overskuddslikviditet.value))}
                                        sx={{ ...getColorStyle(details.Overskuddslikviditet.color) }}
                                        size="small"
                                    />
                                </Tooltip>
                            </TableCell>
                            <TableCell width="21%" sx={{ whiteSpace: 'pre-wrap' }}>
                                {details.com.value}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export const BankTable = ({ banks, validBanks, banksNotIncluded }: BankTableProps) => {
    if (!banks) return null;

    return (
        <Box>
            <BankTableSection 
                title="Boliglånsbanker"
                banks={banks.Boliglånsbanker}
                validBanks={validBanks}
            />
            <BankTableSection 
                title="Spesiallånsbanker"
                banks={banks.Spesiallånsbanker}
                validBanks={validBanks}
            />
            {banksNotIncluded && Object.entries(banksNotIncluded).length > 0 && (
                <TableContainer component={Paper}>
                    <Typography variant="h6" sx={{ p: 2 }}>
                        Banker ikke inkludert
                    </Typography>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Bank</TableCell>
                                <TableCell>Kommentar</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.entries(banksNotIncluded).map(([bank, details]) => (
                                <TableRow key={bank}>
                                    <TableCell>{bank}</TableCell>
                                    <TableCell>{details.com}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Box>
    );
}; 