import { useState } from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Snackbar,
    Alert,
} from '@mui/material';
import { BankNorwegianResponse, Offer } from '../useGetEFR';
import { useBankNorwegianActions } from '../useBankNorwegianActions';

interface Props {
    data: BankNorwegianResponse | null;
    onActionComplete?: () => void;
}

export const BankNorwegianOffers = ({ data, onActionComplete }: Props) => {
    const [selectedOffer, setSelectedOffer] = useState<{
        loanAmount: number;
        repaymentPeriod: number;
    } | null>(null);
    const [confirmRejectOpen, setConfirmRejectOpen] = useState(false);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);

    const { acceptOffer, rejectOffer } = useBankNorwegianActions();

    if (!data?.data?.offers?.items?.length) {
        return null;
    }

    const { application, offers } = data.data;

    const handleAcceptOffer = async (offer: { loanAmount: number; repaymentPeriod: number }) => {
        try {
            await acceptOffer.mutateAsync({
                applicationId: application.applicationID,
                payload: {
                    loanAmount: offer.loanAmount,
                    repaymentPeriod: offer.repaymentPeriod,
                },
            });
            setSuccessMessage('Offer accepted successfully');
            onActionComplete?.();
            // Reload page after 2 seconds
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        } catch (error) {
            console.error('Error accepting offer:', error);
        }
    };

    const handleRejectOffers = async () => {
        try {
            await rejectOffer.mutateAsync({
                applicationId: application.applicationID,
            });
            setConfirmRejectOpen(false);
            setSuccessMessage('Offers rejected successfully');
            onActionComplete?.();
            // Reload page after 2 seconds
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        } catch (error) {
            console.error('Error rejecting offers:', error);
        }
    };

    const formatCurrency = (amount: number) => {
        return new Intl.NumberFormat('no-NO', {
            style: 'currency',
            currency: 'NOK',
            maximumFractionDigits: 0,
        }).format(amount);
    };

    const formatPercent = (value: number) => {
        return new Intl.NumberFormat('no-NO', {
            style: 'percent',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(value);
    };

    return (
        <>
            <Card sx={{ mt: 2 }}>
                <CardContent>
                    <Typography variant="h6" gutterBottom>
                        Bank Norwegian Offers
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Loan Amount</TableCell>
                                    <TableCell>Period (months)</TableCell>
                                    <TableCell>Monthly Payment</TableCell>
                                    <TableCell>Interest Rate</TableCell>
                                    <TableCell>Total Cost</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {offers.items.map((offer: Offer, index: number) => (
                                    <TableRow key={index}>
                                        <TableCell>{formatCurrency(offer.loanAmount)}</TableCell>
                                        <TableCell>{offer.repaymentPeriod}</TableCell>
                                        <TableCell>
                                            {formatCurrency(offer.repaymentPeriodAmountIncludingFees)}
                                        </TableCell>
                                        <TableCell>
                                            {formatPercent(offer.effectiveInterestRate)}
                                        </TableCell>
                                        <TableCell>{formatCurrency(offer.totalLoanCost)}</TableCell>
                                        <TableCell>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => handleAcceptOffer(offer)}
                                                disabled={acceptOffer.isLoading || rejectOffer.isLoading}
                                            >
                                                Accept
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            variant="outlined"
                            color="error"
                            onClick={() => setConfirmRejectOpen(true)}
                            disabled={acceptOffer.isLoading || rejectOffer.isLoading}
                        >
                            Reject All Offers
                        </Button>
                    </Box>
                </CardContent>
            </Card>

            {/* Confirmation Dialog */}
            <Dialog open={confirmRejectOpen} onClose={() => setConfirmRejectOpen(false)}>
                <DialogTitle>Confirm Rejection</DialogTitle>
                <DialogContent>
                    Are you sure you want to reject all Bank Norwegian offers?
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmRejectOpen(false)}>Cancel</Button>
                    <Button onClick={handleRejectOffers} color="error" autoFocus>
                        Reject
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Success Message */}
            <Snackbar
                open={!!successMessage}
                autoHideDuration={2000}
                onClose={() => setSuccessMessage(null)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert 
                    onClose={() => setSuccessMessage(null)} 
                    severity="success"
                    sx={{ width: '100%' }}
                >
                    {successMessage}
                </Alert>
            </Snackbar>
        </>
    );
}; 