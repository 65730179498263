import { useQuery } from "react-query";
import { getAppEnvValue } from "../../services/get-app-env-value";
import { EFRResponse } from "./types";

interface Action {
    category: string;
    description: string;
    role: string;
    status: string;
}

interface Links {
    self: { href: string };
    accept?: { href: string };
    decline?: { href: string };
    offers?: { href: string };
    offer?: { href: string; templated?: boolean };
    downpaymentplan?: { href: string };
    downpaymentplansimulation?: { href: string; templated?: boolean };
    application?: { href: string };
}

interface Application {
    grantedAmount: number;
    maxGrantedAmount: number;
    minGrantedAmount: number;
    dueDayOfMonth: number;
    effectiveInterestRate: number;
    effectiveInterestRateWithoutFees: number;
    installmentFee: number;
    nominalInterestRate: number;
    repaymentPeriodAmount: number;
    repaymentPeriodAmountIncludingFees: number;
    repaymentPeriodMonths: number;
    maxRepaymentPeriodMonths: number;
    minRepaymentPeriodMonths: number;
    refinanceCondition: number;
    setupFee: number;
    isRefinanceOnly: boolean;
    totalLoanCost: number;
    applicationID: string;
    lastUpdated: string;
    product: string;
    location: string;
    status: string;
    actions: Action[];
    agentReference: string;
    _links: Links;
}

export interface Offer {
    grantedAmount: number;
    loanAmount: number;
    effectiveIntrestRate: number;
    effectiveInterestRate: number;
    nominalIntrestRate: number;
    nominalInterestRate: number;
    repaymentPeriodAmount: number;
    repaymentPeriodAmountIncludingFees: number;
    repaymentPeriod: number;
    setupFee: number;
    installmentFee: number;
    isFlex: boolean;
    isRefinanceOnly: boolean;
    totalLoanCost: number;
}

interface Offers {
    refinanceOnlyAllowed: boolean;
    refinanceCondition: number;
    items: Offer[];
    _links: Links;
}

export interface BankNorwegianResponse {
    status: string;
    bankName: string;
    message: string;
    data: {
        application: Application;
        offers: Offers;
    };
}

interface ApiError {
    response?: {
        status: number;
    };
}

export const useGetEFR = (secretId?: string) => {
    const baseUrl = getAppEnvValue('REACT_APP_ONESCORE_API_URL') || 'http://localhost:3000';

    const efrQuery = useQuery<EFRResponse, Error>({
        queryKey: ['EFR', secretId],
        queryFn: () => getEFR(secretId),
        enabled: !!secretId,
    });

    const bankNorwegianQuery = useQuery<BankNorwegianResponse | null, ApiError>({
        queryKey: ['BANK_NORWEGIAN', secretId],
        queryFn: () => getBankNorwegian(secretId),
        enabled: !!secretId,
        onError: (error: ApiError) => {
            if (error?.response?.status !== 404) {
                console.error('Error fetching Bank Norwegian data:', error);
            }
        }
    });

    const getEFR = async (secretId?: string): Promise<EFRResponse> => {
        if (!secretId) throw new Error('No secretId provided');
        try {
            const response = await fetch(`${baseUrl}/loans/calculations/${secretId}`);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
        } catch (error) {
            console.error('Error fetching EFR data:', error);
            throw error;
        }
    };

    const getBankNorwegian = async (secretId?: string): Promise<BankNorwegianResponse | null> => {
        if (!secretId) return null;
        try {
            const response = await fetch(`${baseUrl}/loans/BANK_NORWEGIAN/${secretId}`);
            if (response.status === 404) {
                return null;
            }
            if (!response.ok) {
                return null;
            }
            return response.json();
        } catch (error) {
            console.error('Error fetching Bank Norwegian data:', error);
            throw error;
        }
    };

    return {
        data: efrQuery.data,
        bankNorwegianData: bankNorwegianQuery.data,
        isLoading: efrQuery.isLoading || bankNorwegianQuery.isLoading,
        isError: efrQuery.isError || (bankNorwegianQuery.isError && bankNorwegianQuery.error?.response?.status !== 404),
        error: efrQuery.error || (bankNorwegianQuery.error?.response?.status !== 404 ? bankNorwegianQuery.error : null),
    };
};
